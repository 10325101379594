;
(function($, document, window, undefined) {
    'use strict'; //this is just saying how to validate your JS.  If you use a tool like JSHint you can see your mistakes - test your code quality.
    // declare any variables you want to keep in the scope - keep refering to and updating in your functions.
   var hash = window.location.hash.substr(1);////  console.log(page);

function reduceAppName(appname){
	var length = appname.length;
    var fontSize = 300/length ;
	
	if(fontSize > 28){
			fontSize = 28;		
	}
	return fontSize;
}
	



var owl = $("#testimonial_slider");
var owl_brands = $("#brands_slider");		
	 
	 function doRecaptcha(action){
	grecaptcha.ready(function() {
            grecaptcha.execute('6LeltSgdAAAAAEVRG44wEx9vI3jmhylmrU9ntRM1', {action: 'submit'}).then(function(token) {
				
                $('#contactForm input[name=token]').val(token);
				
				if( token) {
					$('#contactForm').submit();
				}
            });
	
	  });
}
		   
	
				   
    // DOCUMENT READY
    $(function() {
					   
	$.each(page.clubs, function(k, v) {

            $(".app_header_template").clone().removeClass("app_header_template template").addClass("app_header").attr('id', 'club_' + v.id).appendTo('#clubs .apps');
			
            $('#club_' + v.id + ' .club_logo img').attr('src','smalllogo/'+v.id+'-logo.png');
		
			
			
			var appname = v.club_name;
			
			$('#club_' + v.id + ' .appname').text(appname);
			
			 var fontSize = reduceAppName(appname);
			 
			$('#club_' + v.id +' .app_header_cont').css({'background-color' : v.color1,
   									'color' : v.color2,
									'font-size' : fontSize
				});
			$('#club_' + v.id +' .menu_button').css({'color' : v.color2});
				
			
				   });
	
		
		$.each(page.quotes, function(k, v) {
            $("#quotes_section .quote_template").clone().removeClass("quote_template template").addClass("testimonial").attr('id', 'quote_'+k).appendTo('#testimonial_slider');
			$('#quote_'+k+' .description').html(v.short);
			$('#quote_'+k+' .club span').html(v.club);
			$('#quote_'+k+' .club img').attr('src','https://app.connectmyclub.co.uk/logos/'+v.club_id);	
		    });

		

			owl.owlCarousel({
         		items:1,
				loop:true,
				//margin:10,
				nav: false,
  				dots: true,
				autoplay:true,
				autoplayTimeout:7000,
				autoplayHoverPause:true
				});
		
		$.each(page.brands, function(k, v) {
            $("#brands_section .brands_template").clone().removeClass("brands_template template").addClass("brand").attr('id', 'brand_'+k).appendTo('#brands_slider');
			$('#brand_'+k+' img').attr('src',v.logo);	
			$('#brand_'+k+' .name').html(v.name)
		    });
		
		owl_brands.owlCarousel({
         		items:5,
				loop:true,
				//margin:10,
				nav: false,
  				dots: false,
				autoplay:true,
				autoplayTimeout:3000,
				autoplayHoverPause:false
				});
		/**/
		//$('#header_img').css('background-image','url(' + page.header_img + ')');
		
		
		
		
	//initRouteMap();	
  // initTrainingMap();
		
        bindEvents();
    });

    // EVENTS WHICH RELY ON THE DOM
    function bindEvents() {
		
		if( hash ){
			
	$('html, body').animate({
        scrollTop: $('#'+hash).offset().top
    }, 'slow');
		}
	
		
	$('a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
      && 
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 1000, function() {
          // Callback after animation
          // Must change focus!
          var $target = $(target);
          $target.focus();
          if ($target.is(":focus")) { // Checking if the target was focused
            return false;
          } else {
            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
            $target.focus(); // Set focus again
          };
        });
      }
    }
  });
		
$('body').on('click', '.testimonial .read_in_full a', function(event) {
	 event.preventDefault();
	
	let id = $(this).closest('.testimonial').attr('id').replace(/[^\d]+/,'');
	
	let long = page.quotes[id]['quote'];
	
	$(this).closest('.testimonial').find('.description').html(long)
	
	if(long.length > 120){
	   $(this).closest('.testimonial').find('.description').addClass('left_align')
	   }
	
	$(this).remove();
		
	});
		
		

	
$("#features .video_container .play").click(function(event){

	 event.preventDefault();
		$(this).hide();
	
	
	$("#features .video_container video").attr("controls",true);
	$("#features .video_container video").get(0).play()	
		
	});
	
$("#contactForm #submit").click(function(event){
	
	 event.preventDefault();

	doRecaptcha();
});
$("#contactForm").ufForm({
    validators: page.validators.contact
}).on("submitSuccess.ufForm", function(event, data, textStatus, jqXHR) {
	
	var pageURL = $(location).attr("href").replace('#contact','');;
	
	location.href = pageURL;
	
}).on("submitError.ufForm", function(event, data, textStatus, jqXHR) {
	
	grecaptcha.reset();////  console.log(data);
});



   	
		}
})(jQuery, document, window);


function initTrainingMap() {
	
	
	if(page.training_coords.length == 1){
	
	 var deviceLon = parseFloat(page.training_coords[0].lon);
     var deviceLat = parseFloat(page.training_coords[0].lat);
	
	 const myLatlng = { lat: deviceLat, lng: deviceLon };
	 
  			var map = new google.maps.Map(document.getElementById("training_locations_map"), {
									zoom: 19,
									mapTypeId: "terrain",
									center: myLatlng,
								  });
	}
	else{
		
		 var map = new google.maps.Map(document.getElementById("training_locations_map"), {
			 						zoom: 19,
									mapTypeId: "terrain",
								  });
		
	}
  


	var trainingCoords=[];
	
	$.each(page.training_coords, function(k, v) {
	
			 var latCoord = parseFloat(v.lat);
			 var lonCoord = parseFloat(v.lon);
			
				var coords = [{ lat: latCoord,
			  				lng: lonCoord },
							 v.what3words
							];
				trainingCoords.push(coords); 
				
	});////  console.log(trainingCoords)
	
/**/	

if(page.training_coords.length > 1){
	var bounds = new google.maps.LatLngBounds();
	
								for (var i = 0; i < trainingCoords.length; i++) {
									
								  bounds.extend(trainingCoords[i][0]);
							}
		
	map.fitBounds(bounds);
	}

// Create the markers.
var z = trainingCoords.length;

  trainingCoords.forEach(([position, title], i) => {////  console.log(position)
	  
    const marker = new google.maps.Marker({
      position,
      map,
	  title: title,
	  zIndex: z,
	  icon: svgMarker,
      optimized: false,
    });
	z--;
	
	  var contentString =
    '<div>' +
    '<div class="w3w">'+title+'</div>' +
    "</div>";
	
	  var infowindow = new google.maps.InfoWindow({
		content: contentString,
	  });
	
	marker.addListener("click", () => {
	  
	  var title = marker.getTitle();////  console.log(title)
	 
	  infowindow.open({
		  anchor: marker,
		  map,
		  shouldFocus: false,
		});
	  
	  
	  
	  
    });
	
  });
var gridData;	
 map.addListener('bounds_changed', function() {
    const zoom = map.getZoom();
    const loadFeatures = zoom > 17;

    if (loadFeatures) { // Zoom level is high enough
      var ne = map.getBounds().getNorthEast();
      var sw = map.getBounds().getSouthWest();

      // Call the what3words Grid API to obtain the grid squares within the current visble bounding box
      what3words.api
        .gridSectionGeoJson({
          southwest: {
            lat: sw.lat(), lng: sw.lng()
          },
          northeast: {
            lat: ne.lat(), lng: ne.lng()
          }
        }).then(function(data) {
          if (gridData !== undefined) {
            for (var i = 0; i < gridData.length; i++) {
                map.data.remove(gridData[i]);
            }
          }
          gridData = map.data.addGeoJson(data);
        }).catch(console.error);
    }

    // Set the grid display style
    map.data.setStyle({
      visible: loadFeatures,
      strokeColor: '#777',
      strokeWeight: 0.5,
      clickable: false
    });
  });
};

function initRouteMap(){
	const map = new google.maps.Map(document.getElementById("training_route_map"), {
		zoom: 8
	  });
	  
	 var  filename =  page.gpx;
	 
	loadGPXFileIntoGoogleMap(map, filename);
}


function loadGPXFileIntoGoogleMap(map, filename) {
	
    $.ajax({url: filename,
        dataType: "xml",
        success: function(data) {
          var parser = new GPXParser(data, map);
          parser.setTrackColour('#ff7800');     // Set the track line colour
          parser.setTrackWidth(2);          // Set the track line width
          parser.setMinTrackPointDelta(0.000005)      // Set the minimum distance between track points
          parser.centerAndZoom(data);
       		parser.addTrackpointsToMap();         // Add the trackpoints
        	//parser.addRoutepointsToMap();         // Add the routepoints
         // parser.addWaypointsToMap();           // Add the waypoints
        }
    });
}


  
const svgMarker = {
    path: "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
    fillColor: "#ff7800",
    fillOpacity: 0.8,
    strokeWeight: 0.5,
	strokeColor: "#616161",
    rotation: 0,
    scale: 2,
    anchor: new google.maps.Point(12, 24),
  };